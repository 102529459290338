import Sb from "../abstract/StatefulBehavior";

export default class DismissNotificationModal extends Sb {
  constructor(el, props, refs) {
    super();

    this.el = el;
    this.refs = refs;
    this.state = {
      closed: false,
    };
    this.props = props;

    this.sessionKey = "hideNotificationModalMMC";
    this.fadeTimeout = 180;

    // if UA doesn't support localStorage or blocks access to it, bail
    try {
      const hasLocalSession = this.hasLocalSession();
      if (hasLocalSession) {
        this.hideElement();
        this.state.closed = hasLocalSession;
      } else {
        this.el.setAttribute("open", true);
        document.body.classList.add("has-scroll-lock");
      }
    } catch (error) {
      // eslint-disable-next-line
      console.warn("Your browser does not support local session keys.");
    }

    this.update();
    this.bindEvents();
  }

  update = () => {
    const { closed } = this.state;
    this.el.setAttribute("aria-hidden", closed);
    if (closed) {
      setTimeout(this.hideElement, this.fadeTimeout);
    }
  };

  hasLocalSession() {
    const localSession = localStorage.getItem(this.sessionKey);
    let hasLocalSession = false;

    if (localSession) {
      const session = JSON.parse(localSession);

      // Reset local session for new modals or if session expired
      if (
        session.heading === this.props.heading &&
        new Date(session.expirationDate) > new Date()
      ) {
        hasLocalSession = true;
      } else {
        localStorage.removeItem(this.sessionKey);
      }
    }

    return hasLocalSession;
  }

  bindEvents() {
    this.refs.toggleClosed.addEventListener("click", this.closeNotification);
    this.el.addEventListener("click", this.handleClick);
    window.addEventListener("keyup", this.handleKeyUp);
  }

  handleClick = (event) => {
    const isClickInside = this.refs.dialog.contains(event.target);

    if (!isClickInside) {
      this.closeNotification();
    }
  };

  handleKeyUp = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const { key, keyCode, which } = event;

    if (key === "Escape" || key === "Esc" || keyCode === 27 || which === 27) {
      this.closeNotification();
    }
  };

  closeNotification = () => {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + this.props.dismissExpiration);

    localStorage.setItem(
      this.sessionKey,
      JSON.stringify({
        expirationDate,
        heading: this.props.heading,
      })
    );
    this.setState({ closed: true });
    window.removeEventListener("keyup", this.handleKeyUp);
  };

  hideElement = () => {
    Object.assign(this.el.style, { display: "none" });
    this.el.removeAttribute("open");
    document.body.classList.remove("has-scroll-lock");
  };
}
